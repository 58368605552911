import { unref, computed, type MaybeRef } from "vue";
import { useNuxtApp } from "#imports";
import useNotify from "~/composables/useNotify";
import { useQueryClient } from "@tanstack/vue-query";
import {
  createQuery,
  createMutation,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { getErrorMessagesFromError } from "~/utils/helpers";

import type {
  AnyRuleViewModel,
  RuleViewModel,
  TemplatedRuleViewModel,
} from "../models/Library/Rule.viewmodel";
import type { InternalApi } from "nitropack";
import type { AddRulePayload } from "~/server/utils/rules/ruleUpsert";

type ListLibraryRulesBody = {
  libraryId?: string;
  pageSize?: number;
  page?: number;
  search?: string;
  enabledOnly?: string | null;
  sortBy?: string;
  sortOrder?: string;
};

export type UpsertTemplatedRulePayload = Omit<
  TemplatedRuleViewModel,
  "isReadOnly" | "enabled" | "id"
>;

type WithNullableId<T> = Omit<T, "id"> & { id?: null };

export type UpsertRulePayload = WithNullableId<
  Omit<RuleViewModel, "enabled" | "isReadOnly">
>;

export type UpsertAnyRulePayload =
  | UpsertTemplatedRulePayload
  | UpsertRulePayload;

export const useRuleService = () => {
  const { $api } = useNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  // List Rules
  const listLibraryRules = async (
    {
      libraryId,
      page,
      pageSize,
      search,
      enabledOnly,
      sortBy,
      sortOrder,
    }: ListLibraryRulesBody,
    signal?: AbortSignal
  ) => {
    return await $api<InternalApi["/api/hcd/libraries/:id/rules"]["get"]>(
      `/api/hcd/libraries/${libraryId}/rules`,
      {
        signal: signal,
        query: {
          page: page,
          size: pageSize,
          search: search,
          enabledOnly: enabledOnly,
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      }
    );
  };

  const useListLibraryRulesQuery = (
    req: MaybeRef<ListLibraryRulesBody>,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Libraries.Rules.list, req],
      ({ signal }) => listLibraryRules(unref(req), signal),
      {
        createNuxtError,
      }
    );

  const setRuleStatus = async (
    libraryId: string,
    ruleId: string,
    enable: boolean
  ) =>
    await $api(
      `/api/hcd/libraries/${libraryId}/rules/${ruleId}/${
        enable ? "enable" : "disable"
      }`,
      {
        method: "PUT",
      }
    );

  const useSetRuleStatusMutation = () =>
    createMutation(
      ({
        ruleId: id,
        enable,
        libraryId,
      }: {
        ruleId: string;
        enable: boolean;
        libraryId: string;
      }) => setRuleStatus(libraryId, id, enable),
      {
        onSuccess: (_, { ruleId: id, enable }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.get, id],
          });
          notifySuccess(
            "Success",
            `Rule has been ${enable ? "enabled" : "disabled"}`
          );
        },
        onError: (error, y) => {
          notifyError(`Error ${y.enable ? "enabling" : "disabling"} rule`);
        },
      }
    );

  const getRule = async (
    libraryId: string,
    id: string,
    signal?: AbortSignal
  ) => {
    const res = await $api<AnyRuleViewModel>(
      `/api/hcd/libraries/${libraryId}/rules/${id}`,
      { signal }
    );

    return res;
  };

  const useGetRuleQuery = (
    libraryId: MaybeRef<string>,
    ruleId: MaybeRef<string>,
    options?: MyQueryOptions
  ) => {
    const queryKey = computed(() => {
      return [QUERY_KEYS.Libraries.Rules.get, unref(libraryId), unref(ruleId)];
    });

    return createQuery(
      queryKey,
      ({ signal }) => getRule(unref(libraryId), unref(ruleId), signal),
      options
    );
  };

  const updateRule = async (
    id: string,
    ruleId: string,
    payload: AddRulePayload,
    signal?: AbortSignal
  ) => {
    await $api<InternalApi["/api/hcd/libraries/:id/rules/:ruleId"]["put"]>(
      `/api/hcd/libraries/${id}/rules/${ruleId}`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
        body: payload,
        signal: signal,
      }
    );
  };

  const useUpdateRuleMutation = () =>
    createMutation(
      ({
        id,
        ruleId,
        payload,
      }: {
        id: string;
        ruleId: string;
        payload: AddRulePayload;
      }) => updateRule(id, ruleId, payload),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.get, y.id, y.ruleId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.list, y.id],
          });
          notifySuccess("Success", "Rule updated");
        },
        onError: (error) => {
          notifyError(getErrorMessagesFromError(error).join("\n"));
        },
      }
    );

  const createRule = async (
    id: string,
    payload: AddRulePayload,
    signal?: AbortSignal
  ) => {
    return await $api<InternalApi["/api/hcd/libraries/:id/rules"]["post"]>(
      `/api/hcd/libraries/${id}/rules`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: payload,
        signal: signal,
      }
    );
  };

  const useCreateRuleMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: AddRulePayload }) =>
        createRule(id, payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.list],
          });
          notifySuccess("Success", "Rule updated");
        },
        onError: (e) => {
          notifyError(
            `Error updating rule`,
            getErrorMessagesFromError(e).join("\n")
          );
        },
      }
    );

  const deleteRule = async (
    libraryId: string,
    ruleId: string,
    signal?: AbortSignal
  ) => {
    await $api<InternalApi["/api/hcd/libraries/:id/rules/:ruleId"]["delete"]>(
      `/api/hcd/libraries/${libraryId}/rules/${ruleId}`,
      {
        method: "DELETE",
        signal: signal,
      }
    );
  };

  const useDeleteRuleMutation = () =>
    createMutation(
      ({ libraryId, ruleId }: { libraryId: string; ruleId: string }) =>
        deleteRule(libraryId, ruleId),
      {
        onSuccess: (_, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.get, y.libraryId, y.ruleId],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.Rules.list, y.libraryId],
          });
          notifySuccess("Success", `Rule has been deleted`);
        },
        onError: () => notifyError(`Error deleting rule`),
      }
    );

  return {
    listLibraryRules,
    useListLibraryRulesQuery,
    useGetRuleQuery,
    useSetRuleStatusMutation,
    setRuleStatus,

    useCreateRuleMutation,
    createRule,

    useUpdateRuleMutation,
    updateRule,

    useDeleteRuleMutation,
  };
};
